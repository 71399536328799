var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('form',[_c('h4',[_vm._v("Filters Input")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.keyword),expression:"filter.keyword"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.filter.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "keyword", $event.target.value)}}})])])],1),_c('b-col',{staticClass:"p-0 mt-2",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"disabled":!_vm.filter.keyword},on:{"click":function($event){_vm.filter.keyword = ''}}},[_vm._v(" Reset ")])])],1),_c('div',{staticClass:"border__dash"}),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.question,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * 10 + index + 1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer ? item.customer.name : '-')+" ")]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer ? item.customer.email : '-')+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('router-link',{attrs:{"to":'/tax-recomendation-helper/'+ item.uuid}},[_c('b-badge',{staticClass:"d-flex align-items-center badge-glow",staticStyle:{"gap":"5px"},attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (item.uuid) + "-send-icon"),"icon":"AlertCircleIcon","size":"12"}}),_vm._v(" Detail ")],1)],1),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Detail Data","target":("invoice-row-" + (item.uuid) + "-send-icon")}})],1)]}}])}),(_vm.result.total > 0)?_c('div',{staticClass:"m-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-1"},[_c('small',[_vm._v("Showing "+_vm._s(_vm.result.from)+" to "+_vm._s(_vm.result.to)+" from "+_vm._s(_vm.result.total))])]),_c('div',{staticClass:"col"},[_c('pagination',{attrs:{"data":_vm.result,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }