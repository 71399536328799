<template>
	<b-card>
		<form>
			<h4>Filters Input</h4>
			<b-row>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Name:</label>
						<input type="text" class="form-control" v-model="filter.keyword" >
					</div>
				</b-col>
				<!-- <b-col cols="12" lg="4">
					<div class="form-group">
						<label>Phone:</label>
						<input type="text" class="form-control" v-model="filter.keyword" >
					</div>
				</b-col> -->
			</b-row>
			<b-col cols="12" class="p-0 mt-2">
				<button 
					:disabled="!filter.keyword" 
					@click="filter.keyword = ''" 
					class="btn btn-outline-secondary"
				>
					Reset
				</button>
			</b-col>
		</form>
		<div class="border__dash"></div>
		<b-table 
			striped 
			hover 
			:items="question" 
			:fields="fields"
			:busy="isLoading"
			show-empty
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			
			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>

			<template v-slot:cell(name)="{ item }">
				{{ item.customer ? item.customer.name : '-' }}
			</template>

			<template v-slot:cell(email)="{ item }">
				{{ item.customer ? item.customer.email : '-' }}
			</template>
			
			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<router-link
						:to="'/tax-recomendation-helper/'+ item.uuid"
					>
						<b-badge 
							variant="primary" 
							class="d-flex align-items-center badge-glow"
							style="gap:5px;"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="AlertCircleIcon"
								class="cursor-pointer"
								size="12"
							/>
								Detail
						</b-badge>
					</router-link>
					<b-tooltip
						title="Detail Data"
						class="cursor-pointer"
						:target="`invoice-row-${item.uuid}-send-icon`"
					/>
				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import 
  { 
	BRow,
	BCol,
    BCard,
    BCardText,
    BTable,
    BTooltip,
	BButton,
    BPagination,
	BSpinner,
	BBadge,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BTable,
		BTooltip,
		BButton,
		BPagination,
		BSpinner,
		BBadge,
	},

	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no', 'name', 'email', 'date', 'actions'],
			question: [],
			dateDefault: null,
			filter: {
				keyword:''
			}
		}
	},

	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},

	setup(props) {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},

	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		getData(page) { //page = 1
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/fill-questions/compliting-profile', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.question = response.data.data.data	
				this.currentPage = response.data.data.current_page
				this.isLoading = false
				})
		},
		
	},

  mounted() {
		this.getData();
	}
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
